import * as React from "react"

function IconPlaces(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M13 7c0 4-5 8-5 8s-5-4-5-8a5 5 0 0110 0z" stroke="#5D6368" />
            <circle cx={8} cy={7} r={1.5} stroke="#5D6368" />
        </svg>
    )
}

export default IconPlaces
