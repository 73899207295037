import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Block, Text } from '../layout';
import { Row } from './components';

function Table({ type = 1, columns = [], rows = [], customActionsWidth = 0, next, hasMore = false }) {
    const rowsRef = useRef(null);
    const actionsRef = useRef(null);
    const [actionsWidth, setActionsWidth] = useState(customActionsWidth);

    useEffect(() => {
        if (!customActionsWidth && actionsRef.current) {
            setActionsWidth(actionsRef.current.offsetWidth);
        }
    }, [columns, rows]);

    return (
        <Block mt={32} height="100%" style={{ overflow: "hidden" }}>
            <Block row pl={24}>
                {columns.map((column, columnIndex) => {
                    return (
                        <Block key={columnIndex} flex>
                            <Text size={12} height={20} bold>{column.label}</Text>
                        </Block>
                    );
                })}
                <Block width={actionsWidth} />
            </Block>
            <Block ref={rowsRef} mt={8} mb={type === 1 ? 4 : 0} flex style={{ overflowY: "auto" }}>
                {type === 2 && <Block width="100%" height={1} color="greyAlmostWhite" />}
                <InfiniteScroll
                    height="100%"
                    dataLength={rows.length}
                    next={next}
                    hasMore={hasMore}
                >
                    {rows.map((row, rowIndex) => {
                        return (
                            <Row ref={actionsRef} actionsWidth={actionsWidth} key={rowIndex} rowIndex={rowIndex} type={type} row={row} columns={columns}>
                                {row.subRowsOpen && row.subRows}
                            </Row>
                        );
                    })}
                </InfiniteScroll>
            </Block>
        </Block>
    );
}

export default Table;