import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Header } from '../../../../components'
import { Block, Button, Text } from '../../../../components/layout'
import FileSelectItem from '../../../../components/layout/FileInput/components/FileSelectItem'
import { SvgIconClose, SvgIconSearch } from '../../../../svgs'
import colors from '../../../../utils/colors'

Modal.setAppElement('body');

function FileInput(
    {
        value,
        focus,
        onChange
    }
) {
    const hiddenFileInput = useRef(null);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [fileSelected, setFileSelected] = useState(value);

    const [queryFiles, { loading }] = useLazyQuery(
        gql`
            query files($first: Int, $after: String, $search: String) {
                files(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            mimetype
                            url
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.files) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.files.edges] : data.files.edges,
                        pageInfo: data.files.pageInfo,
                        totalCount: data.files.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutateFileCreate, { loading: loadingFileCreate }] = useMutation(
        gql`
            mutation fileCreate($data: Upload!) {
                fileCreate(data: $data) {
                    id
                    name
                    mimetype
                    url
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.fileCreate) {
                    result.edges.unshift({ cursor: data.fileCreate.id, node: data.fileCreate });
                    setResult({ ...result });
                    onChange(data.fileCreate);
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryFiles({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        if (modalIsOpen) {
            requestData();
        }
    }, [search, modalIsOpen]);

    useEffect(() => {
        if (focus) {
            setModalIsOpen(true);
        }
    }, [focus]);

    return (
        <>
            <div style={{ overflow: "hidden" }}>
                {value ? value.name : ""}
                <input ref={hiddenFileInput} type="file" style={{ display: "none" }} onChange={(e) => {
                    if (e.target.files[0]) {
                        mutateFileCreate({ variables: { data: e.target.files[0] } });
                        hiddenFileInput.current.value = null;
                    }
                }} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: "rgb(53,56,59,0.8)"
                    },
                    content: {
                        maxWidth: 1028,
                        padding: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        border: "none",
                        borderRadius: 0,
                    }
                }}
            >
                <Block height="100%">
                    <Header
                        leftChildren={
                            <Block height="100%">
                                <Block width={229} height="100%" middle>
                                    <Text size={18} height={32} ml={24}>Select file</Text>
                                </Block>
                            </Block>
                        }
                        rightChildren={
                            <Block row>
                                <Button color="greyLightest" textColor="greyDark" text="Add file" loading={loadingFileCreate} onClick={() => hiddenFileInput.current.click()} />
                                <Button style={{ width: 52, minWidth: 52, textAlign: "center" }} onClick={() => { setModalIsOpen(false); setFileSelected(value) }}>
                                    <SvgIconClose />
                                </Button>
                            </Block>
                        }
                    />
                    <ModalContent color="greyAlmostWhite">
                        <Block pt={32} pl={24} pr={24} pb={24} flex style={{ overflow: "hidden" }}>
                            <Block style={{ position: "relative", flexGrow: 0 }}>
                                <Block style={{ position: "absolute", left: 20, top: 18 }}>
                                    <SvgIconSearch />
                                </Block>
                                <SearchInput type="text" placeholder="Search for files" onChange={(e) => setSearch(e.target.value)} />
                            </Block>
                            <Block flex style={{ flexGrow: 1, overflow: "hidden" }} mt={20} mb={20}>
                                <InfiniteScroll
                                    height="100%"
                                    dataLength={result.edges.length}
                                    next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                                    hasMore={result.pageInfo.hasNextPage}
                                >
                                    <Block row style={{ flexWrap: "wrap", overflow: "auto" }} ml={-10} mr={-10} mt={-10} mb={-10}>
                                        {result.edges.map((edge) => (
                                            <FileSelectItem
                                                key={edge.cursor}
                                                name={edge.node.name}
                                                mimetype={edge.node.mimetype}
                                                url={edge.node.url}
                                                selected={fileSelected && fileSelected.id === edge.cursor}
                                                onSelect={() => (fileSelected && fileSelected.id) === edge.cursor ? setFileSelected(null) : setFileSelected(edge.node)}
                                            />
                                        ))}
                                    </Block>
                                </InfiniteScroll>
                            </Block>
                            <Block style={{ flexGrow: 0 }}>
                                <Button text="Submit" onClick={() => {
                                    onChange(fileSelected);
                                    setModalIsOpen(false);
                                }} />
                            </Block>
                        </Block>
                    </ModalContent>
                </Block>
            </Modal>
        </>
    );
}

export default FileInput

const ModalContent = styled(Block)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    overflow: auto;
`;

const SearchInput = styled.input`
    font-family: "Medium";
    height: 52px;
    max-width: 580px;
    border: none;
    padding-left: 44px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-right: 1px solid ${colors.greyAlmostWhite} ;
`;