import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentEditor, Header, ModalThatSlides } from '../../components';
import { Block, Button, Text, TextInput } from '../../components/layout';
import { SvgIconArrowLeft, SvgIconMetatags } from '../../svgs';
import toast from '../../utils/toast';
import validateFormSchema from '../../utils/validateFormSchema';

function ContentTypeEdit() {
    const history = useHistory();
    let { contentTypeId } = useParams();
    const [result, setResult] = useState(null);
    const [isModalMetatagsOpen, setIsModalMetatagsOpen] = useState(false);

    let submitForm = null;

    const [queryContentType, { loading }] = useLazyQuery(
        gql`
            query contentType($id: ID!) {
                contentType(id: $id) {
                    id
                    name
                    key
                    content
                    template {
                        id
                        structure
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentType) {
                    setResult(data.contentType);
                }
            }
        }
    );

    const [mutationContentTypeUpdate, { loading: loadingContentTypeUpdate }] = useMutation(
        gql`
            mutation contentTypeUpdate($id: ID!, $content: Json) {
                contentTypeUpdate(id: $id, content: $content) {
                    id
                    content
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentTypeUpdate) {
                    setResult({ ...result, ...data.contentTypeUpdate });
                    toast.success(`Saved`);
                }
            }
        }
    );

    const [mutationContentTypeUpdateGeneric, { loading: loadingContentTypeUpdateGeneric }] = useMutation(
        gql`
            mutation contentTypeUpdate($id: ID!, $name: String, $key: String) {
                contentTypeUpdate(id: $id, name: $name, key: $key) {
                    id
                    name
                    key
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.contentTypeUpdate) {
                    setResult({ ...result, ...data.contentTypeUpdate });
                }
            }
        }
    );

    useEffect(() => {
        queryContentType({ variables: { id: contentTypeId } });
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/content-types")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                {result && <Text size={18} height={32} ml={24}>{result.name}</Text>}
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setIsModalMetatagsOpen(true)}>
                                <SvgIconMetatags />
                                <Text size={14} height={28} ml={8}>Settings</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Save" onClick={() => submitForm()} loading={loadingContentTypeUpdate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} pb={64} style={{ overflow: "auto" }}>
                    {result && <ContentEditor
                        onSubmit={(values) => mutationContentTypeUpdate({
                            variables: {
                                id: contentTypeId,
                                content: values
                            }
                        })}
                        bindSubmitForm={(method) => submitForm = method}
                        values={result.content}
                        structure={result.template.structure}
                    />}
                </Block>
            </Block>
            <ModalThatSlides title="Manage Settings" width="820px" isOpen={isModalMetatagsOpen} onRequestClose={() => setIsModalMetatagsOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={result}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    key: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationContentTypeUpdateGeneric({
                                variables: {
                                    id: contentTypeId,
                                    name: values.name,
                                    key: values.key,
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Generic</Text>
                                        <Block mt={12}>
                                            <TextInput label="Name" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Key" name="key" onChange={handleChange} onBlur={handleBlur} value={values.key} error={errors.key} />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingContentTypeUpdateGeneric} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </ModalThatSlides>
        </>
    );
}

export default ContentTypeEdit;