import * as React from "react"

function IconAreas(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M3 13h10V3H3v10z" stroke="#5D6368" strokeDasharray="2 2" />
        </svg>
    )
}

export default IconAreas
