import * as React from "react"

function IconEvents(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                stroke="#5D6368"
                d="M2 3h12v11H2zM2 8h12M8 11l3-3M2 11h12M5 8v6M5 1v1.6M8 8v6M11 8v6M11 1v1.6"
            />
        </svg>
    )
}

export default IconEvents
