import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, ModalThatSlides, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, SelectInput, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import colors from '../../utils/colors';
import validateFormSchema from '../../utils/validateFormSchema';

function Users() {
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [role, setRole] = useState(null);
    const [counters, setCounters] = useState({ promoters: 0, users: 0 });
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);
    const [modalEditIsOpen, setModalEditIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryUserCounters, { loading: loadingCounters }] = useLazyQuery(
        gql`
            query userCounters {
                userCounters
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userCounters) {
                    setCounters(data.userCounters)
                }
            }
        }
    );

    const [queryUsers, { loading }] = useLazyQuery(
        gql`
            query users($first: Int, $after: String, $search: String, $role: String) {
                users(first: $first, after: $after, search: $search, role: $role) {
                    edges {
                        node {
                            id
                            name
                            email
                            role
                            company
                            phone
                            city {
                                name
                                district
                                country
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.users) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.users.edges] : data.users.edges,
                        pageInfo: data.users.pageInfo,
                        totalCount: data.users.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationUserCreate, { loading: loadingUserCreate }] = useMutation(
        gql`
            mutation userCreate($name: String!, $email: String!, $role: String!) {
                userCreate(name: $name, email: $email, role: $role) {
                    id
                    name
                    email
                    role
                    company
                    phone
                    city {
                        name
                        district
                        country
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userCreate) {
                    setModalCreateIsOpen(false);
                    requestData();
                }
            }
        }
    );

    const [mutationUserUpdate, { loading: loadingUserUpdate }] = useMutation(
        gql`
            mutation userUpdate($id: ID!, $name: String, $email: String, $role: String) {
                userUpdate(id: $id, name: $name, email: $email, role: $role) {
                    id
                    name
                    email
                    role
                    company
                    phone
                    city {
                        name
                        district
                        country
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.userUpdate.id);
                    setModalEditIsOpen(null);
                    if (index !== -1) {
                        result.edges[index].node = data.userUpdate;
                        setResult(...result);
                    }
                }
            }
        }
    );

    const [mutationUserDelete, { loading: loadingUserDelete }] = useMutation(
        gql`
            mutation userDelete($id: ID!) {
                userDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.userDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryUsers({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined,
                role: !!role ? role : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [search, role]);

    useEffect(() => {
        queryUserCounters();
    }, []);

    const roles = {
        "role.developer": "Developer",
        "role.administrator": "Administrator",
        "role.user": "User",
        "role.promoter": "Promoter"
    }

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<Button style={{ minWidth: 309 }} text="Add user" onClick={() => setModalCreateIsOpen(true)} />}
                />
                <Block row pl={20} pt={32}>
                    <Block width={236} height={52} color={colors.greyDark} row center pl={24} pr={24} space="between" style={{ cursor: "pointer" }} onClick={() => setRole(null)}>
                        <Text size={14} height={20} color={"white"}>All</Text>
                        {/* <Text size={14} height={20} color={"white"}>{counters.users}</Text> */}
                    </Block>
                    <Block width={1} height={52} color={colors.grey} />
                    <Block width={236} height={52} color={colors.greyDark} row center pl={24} pr={24} space="between" style={{ cursor: "pointer" }} onClick={() => setRole("role.user")}>
                        <Text size={14} height={20} color={"white"}>Users</Text>
                        <Text size={14} height={20} color={"white"}>{counters.users}</Text>
                    </Block>
                    <Block width={1} height={52} color={colors.grey} />
                    <Block width={236} height={52} color={colors.greyDark} row center pl={24} pr={24} space="between" style={{ cursor: "pointer" }} onClick={() => setRole("role.promoter")}>
                        <Text size={14} height={20} color={"white"}>Promoters</Text>
                        <Text size={14} height={20} color={"white"}>{counters.promoters}</Text>
                    </Block>
                </Block>
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Email", key: "email" },
                            { label: "Company", key: "company" },
                            { label: "Phone", key: "phone" },
                            { label: "Location", key: "location" },
                            { label: "Role", key: "role" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, location: !!edge.node.city ? `${edge.node.city.name}, ${edge.node.city.district}` : "", role: roles[edge.node.role], actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalEditIsOpen(edge.node)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create user"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Users</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    email: yup.string().email().required(),
                                    role: yup.string().required(),
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationUserCreate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="email" label="Email" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.email} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Role" name="role" onBlur={handleBlur} options={Object.keys(roles).map((roleKey) => ({ value: roleKey, label: roles[roleKey] }))} value={values.role} error={errors.role} />
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingUserCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete user"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this user?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this user.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingUserDelete} onClick={() => mutationUserDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
            <ModalThatSlides title="Manage Settings" width="820px" isOpen={!!modalEditIsOpen} onRequestClose={() => setModalEditIsOpen(null)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={modalEditIsOpen || {}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    email: yup.string().email().required(),
                                    role: yup.string().required(),
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationUserUpdate({
                                variables: {
                                    id: modalEditIsOpen.id,
                                    name: values.name,
                                    email: values.email,
                                    role: values.role
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Generic</Text>
                                        <Block mt={12}>
                                            <TextInput name="name" label="Name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="email" label="Email" type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} error={errors.email} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Role" name="role" onBlur={handleBlur} options={Object.keys(roles).map((roleKey) => ({ value: roleKey, label: roles[roleKey] }))} value={values.role} error={errors.role} />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingUserUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </ModalThatSlides>
        </>
    );
}

export default Users;