import { useMutation } from '@apollo/react-hooks';
import cuid from 'cuid';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Block, Button } from '../../components/layout';
import colors from '../../utils/colors';
import GridEvents from './components/GridEvents';
import GridLinks from './components/GridLinks';
import GridSessions from './components/GridSessions';

function Events() {
    const [rows, setRows] = useState([]);
    const [event, setEvent] = useState(null);
    const [session, setSession] = useState(null);

    const [mutationEventCreateMultiple, { loading: loadingEventCreate }] = useMutation(
        gql`
            mutation eventCreateMultiple($events: [InputEvent!]!) {
                eventCreateMultiple(events: $events)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                console.log(data)
                if (data.eventCreateMultiple) {
                    setRows([]);
                    alert("All events created!");
                }
            }
        }
    );

    return (
        <Block flex>
            <Block row right color={colors.greyWhite}>
                <Button text="Add event" onClick={() => setRows([...rows, { id: cuid(), sessions: [] }])} />
                <Block ml={1}>
                    <Button
                        text="Save"
                        onClick={async () => {
                            mutationEventCreateMultiple({
                                variables: {
                                    events: rows
                                }
                            })
                        }}
                    />
                </Block>
            </Block>
            <GridEvents
                rows={rows}
                onChangeRows={(rows) => {
                    setRows(rows);
                }}
                // onClickCreate={() => {
                //     setRows([...rows, { id: cuid(), sessions: [] }]);
                // }}
                onClickDelete={(_row, index) => {
                    rows.splice(index, 1);
                    setRows([...rows]);
                }}
                onClickSessions={(row, index) => {
                    setEvent({ ...row, index });
                }}
            />
            <GridSessions
                rows={event && event.sessions}
                onRequestClose={() => setEvent(null)}
                onChangeRows={(sessions) => {
                    rows[event.index].sessions = sessions;
                    event.sessions = sessions;
                    setRows([].concat(rows));
                    setEvent({ ...event });
                }}
                onClickCreate={() => {
                    const sessions = [...event.sessions, { id: cuid(), affiliates: [] }];
                    rows[event.index].sessions = sessions;
                    event.sessions = sessions;
                    setRows([].concat(rows));
                    setEvent({ ...event });
                }}
                onClickDelete={(_session, index) => {
                    event.sessions.splice(index, 1);
                    rows[event.index].sessions = event.sessions;
                    setRows([].concat(rows));
                    setEvent({ ...event });
                }}
                onClickAffiliates={(row, index) => {
                    setSession({ ...row, index });
                }}
            />
            <GridLinks
                rows={session && session.affiliates}
                onRequestClose={() => setSession(null)}
                onChangeRows={(affiliates) => {
                    rows[event.index].sessions[session.index].affiliates = affiliates;
                    session.affiliates = affiliates;
                    setRows([].concat(rows));
                    setSession({ ...session });
                }}
                onClickCreate={() => {
                    const sessions = [...session.affiliates, { id: cuid() }];
                    rows[event.index].sessions[session.index].affiliates = sessions;
                    session.affiliates = sessions;
                    setRows([].concat(rows));
                    setSession({ ...session });
                }}
                onClickDelete={(_session, index) => {
                    session.affiliates.splice(index, 1);
                    rows[event.index].sessions[session.index].affiliates = session.affiliates;
                    setRows([].concat(rows));
                    setSession({ ...session });
                }}
            />
        </Block>
    );
}

export default Events;