import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentEditor, Header, ModalThatSlides } from '../../components';
import { Block, Button, FileInput, SelectInput, Text, TextArea, TextInput } from '../../components/layout';
import { SvgIconArrowLeft, SvgIconMetatags } from '../../svgs';
import toast from '../../utils/toast';
import validateFormSchema from '../../utils/validateFormSchema';

function ArticleEdit() {
    const history = useHistory();
    let { articleId } = useParams();
    const [result, setResult] = useState(null);
    const [isModalMetatagsOpen, setIsModalMetatagsOpen] = useState(false);

    let submitForm = null;

    const [queryArticle, { loading }] = useLazyQuery(
        gql`
            query article($id: ID!) {
                article(id: $id) {
                    id
                    slug
                    title
                    excerpt
                    thumbnail
                    content
                    status
                    metatags
                    date
                    time
                    template {
                        id
                        structure
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.article) {
                    setResult(data.article);
                }
            }
        }
    );

    const [mutationArticleUpdate, { loading: loadingArticleUpdate }] = useMutation(
        gql`
            mutation articleUpdate($id: ID!, $content: Json) {
                articleUpdate(id: $id, content: $content) {
                    id
                    content
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articleUpdate) {
                    setResult({ ...result, ...data.articleUpdate });
                    toast.success(`Saved`);
                }
            }
        }
    );

    const [mutationArticleUpdateGeneric, { loading: loadingArticleUpdateGeneric }] = useMutation(
        gql`
            mutation articleUpdate($id: ID!, $title: String, $excerpt: String, $thumbnail: Json, $slug: String, $status: ArticleStatus, $date: String, $time: String) {
                articleUpdate(id: $id, title: $title, excerpt: $excerpt, thumbnail: $thumbnail, slug: $slug, status: $status, date: $date, time: $time) {
                    id
                    title
                    excerpt
                    thumbnail
                    slug
                    status
                    date
                    time
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articleUpdate) {
                    setResult({ ...result, ...data.articleUpdate });
                }
            }
        }
    );

    const [mutationArticleUpdateMetatags, { loading: loadingArticleUpdateMetatags }] = useMutation(
        gql`
            mutation articleUpdate($id: ID!, $metatags: Json) {
                articleUpdate(id: $id, metatags: $metatags) {
                    id
                    metatags
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.articleUpdate) {
                    setResult({ ...result, ...data.articleUpdate });
                }
            }
        }
    );

    useEffect(() => {
        queryArticle({ variables: { id: articleId } });
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/articles")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                {result && <Text size={18} height={32} ml={24}>{result.title}</Text>}
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setIsModalMetatagsOpen(true)}>
                                <SvgIconMetatags />
                                <Text size={14} height={28} ml={8}>Settings</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Save" onClick={() => submitForm()} loading={loadingArticleUpdate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} pb={64} style={{ overflow: "auto" }}>
                    {result && <ContentEditor
                        onSubmit={(values) => mutationArticleUpdate({
                            variables: {
                                id: articleId,
                                content: values
                            }
                        })}
                        bindSubmitForm={(method) => submitForm = method}
                        values={result.content}
                        structure={result.template.structure}
                    />}
                </Block>
            </Block>
            <ModalThatSlides title="Manage Settings" width="820px" isOpen={isModalMetatagsOpen} onRequestClose={() => setIsModalMetatagsOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={result}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    title: yup.string().required(),
                                    excerpt: yup.string(),
                                    thumbnail: yup.object().nullable(),
                                    slug: yup.string().required(),
                                    status: yup.string().required(),
                                    date: yup.date().required(),
                                    time: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationArticleUpdateGeneric({
                                variables: {
                                    id: articleId,
                                    title: values.title,
                                    excerpt: values.excerpt,
                                    thumbnail: values.thumbnail,
                                    slug: values.slug,
                                    status: values.status,
                                    date: values.date,
                                    time: values.time
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Generic</Text>
                                        <Block mt={12}>
                                            <FileInput label="Thumbnail" name="thumbnail" onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Title" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} error={errors.title} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextArea label="Excerpt" name="excerpt" onChange={handleChange} onBlur={handleBlur} value={values.excerpt} error={errors.excerpt} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Slug" name="slug" onChange={handleChange} onBlur={handleBlur} value={values.slug} error={errors.slug} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Status" name="status" onBlur={handleBlur} value={values.status} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Date" name="date" type="date" onChange={handleChange} onBlur={handleBlur} value={values.date} error={errors.date} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Time" name="time" type="time" onChange={handleChange} onBlur={handleBlur} value={values.time} error={errors.time} />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingArticleUpdateGeneric} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
                <Block mt={12}>
                    <Formik
                        initialValues={(result && result.metatags) || {}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationArticleUpdateMetatags({
                                variables: {
                                    id: articleId,
                                    metatags: values
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Metatags</Text>
                                        <Block mt={12}>
                                            <TextInput label="Title" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} error={errors.title} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Description" name="description" onChange={handleChange} onBlur={handleBlur} value={values.description} error={errors.description} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Keywords" name="keywords" onBlur={handleBlur} value={values.keywords} error={errors.keywords} isMulti />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingArticleUpdateMetatags} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </ModalThatSlides>
        </>
    );
}

export default ArticleEdit;