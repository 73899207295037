import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, Text } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCheck, SvgIconClose, SvgIconModalDelete } from '../../svgs';
import colors from '../../utils/colors';

function PromotersToApprove() {
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalRejectIsOpen, setModalRejectIsOpen] = useState(null);
    const [modalApproveIsOpen, setModalApproveIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryPromotersToApprove, { loading }] = useLazyQuery(
        gql`
            query promotersToApprove($first: Int, $after: String, $search: String) {
                promotersToApprove(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            email
                            company
                            phone
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.promotersToApprove) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.promotersToApprove.edges] : data.promotersToApprove.edges,
                        pageInfo: data.promotersToApprove.pageInfo,
                        totalCount: data.promotersToApprove.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationPromoterApprove, { loading: loadingPromoterApprove }] = useMutation(
        gql`
            mutation promoterApprove($id: ID!, $approved: Boolean!) {
                promoterApprove(id: $id, approved: $approved) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.promoterApprove) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.promoterApprove.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalRejectIsOpen(null);
                        setModalApproveIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryPromotersToApprove({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<></>}
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Email", key: "email" },
                            { label: "Company", key: "company" },
                            { label: "Phone", key: "phone" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, actions: <><ButtonAction icon={<SvgIconCheck color={colors.greyDark} />} onClick={() => setModalApproveIsOpen(edge.cursor)} /><ButtonAction icon={<SvgIconClose color={colors.greyDark} />} onClick={() => setModalRejectIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                width={300}
                title="Approve promoter"
                isOpen={!!modalApproveIsOpen}
                onRequestClose={() => setModalApproveIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to approve this promoter?</Text>
                <Block mt={32}>
                    <Button text="Approve" loading={loadingPromoterApprove} onClick={() => mutationPromoterApprove({ variables: { id: modalApproveIsOpen, approved: true } })} />
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Reject promoter"
                isOpen={!!modalRejectIsOpen}
                onRequestClose={() => setModalRejectIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to reject this promoter?</Text>
                <Block mt={32}>
                    <Button text="Reject" loading={loadingPromoterApprove} onClick={() => mutationPromoterApprove({ variables: { id: modalRejectIsOpen, approved: false } })} />
                </Block>
            </Modal>
        </>
    );
}

export default PromotersToApprove;