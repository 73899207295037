import { useLazyQuery } from "@apollo/react-hooks";
import { FieldArray } from "formik";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { Block, Button, SelectInput, TextInput } from "../../../../components/layout";

function TicketsInput(
    {
        name,
        label,
        errors,
        onBlur,
        onChange,
        value,
        ticketOffices
    }
) {
    return (
        <>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <>
                        {value && value.length > 0 && (
                            value.map((item, index) => (
                                <Block key={index}>
                                    <Block mt={12}>
                                        <SelectInput name={`${name}[${index}].name`} label="Name" onChange={onChange} onBlur={onBlur} error={errors && errors[`${name}[${index}].name`]} options={ticketOffices} />
                                    </Block>
                                    <Block mt={12}>
                                        <TextInput name={`${name}[${index}].url`} label="Url" value={item.url} error={errors && errors[`${name}[${index}].url`]} onBlur={onBlur} onChange={onChange} />
                                    </Block>
                                    <Block mt={12}>
                                        <Button type="button" text="Remove" onClick={() => arrayHelpers.remove(index)} />
                                    </Block>
                                </Block>
                            ))
                        )}
                        <Block mt={12}>
                            <Button type="button" text="Add more" onClick={() => arrayHelpers.push({})} />
                        </Block>
                    </>
                )}
            />
        </>
    );
}

export default React.memo(TicketsInput);