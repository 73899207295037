import cuid from 'cuid';
import { FieldArray } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonAddElement } from '..';
import { SvgIconSettings, SvgIconTextLine } from '../../../../../svgs';
import colors from '../../../../../utils/colors';
import { Block, Text, TextInput } from '../../../../layout';

function List({
    path,
    title,
    fieldKey,
    properties = {
        fields: []
    },
    getElement,
    onMoveUp,
    onMoveDown,
    onDelete,
    onChange,
    onBlur
}) {
    const [isOpen, setIsOpen] = useState(false);

    const node = useRef();
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Wrapper color="white">
            <Block>
                <Settings pointer onClick={() => setIsOpen(true)}>
                    <SvgIconSettings color={colors.greyDark} />
                </Settings>
                <Block pl={24} pr={24} pt={16} pb={16}>
                    <Text size={14} height={20} mb={16}>{title}</Text>
                    <Block row>
                        <Block mr={12}>
                            <TextInput label="Key" name={`${path}.key`} value={fieldKey} onChange={onChange} onBlur={onBlur} />
                        </Block>
                        <Block>
                            <TextInput label="Title" name={`${path}.title`} value={title} onChange={onChange} onBlur={onBlur} />
                        </Block>
                    </Block>
                    <Block>
                        {properties.fields && <FieldArray
                            name={`${path}.properties.fields`}
                            render={arrayHelpers => {
                                if (properties.fields.length === 0) {
                                    return (
                                        <ButtonAddElement onElementSelected={(type) => {
                                            arrayHelpers.insert(0, {
                                                id: cuid(),
                                                type,
                                                title: `${type} Title`,
                                                key: `${type}Key`,
                                            });
                                        }} />
                                    )
                                }

                                return (
                                    properties.fields.map((field, fieldIndex) => {
                                        const Element = getElement(field.type);
                                        return (
                                            <Block key={field.id}>
                                                <Item mt={12}>
                                                    <Element
                                                        path={`${path}.properties.fields[${fieldIndex}]`}
                                                        title={field.title}
                                                        type={field.type}
                                                        fieldKey={field.key}
                                                        properties={field.properties}
                                                        getElement={getElement}
                                                        onMoveUp={() => {
                                                            arrayHelpers.move(fieldIndex, fieldIndex - 1);
                                                        }}
                                                        onMoveDown={() => {
                                                            arrayHelpers.move(fieldIndex, fieldIndex + 1);
                                                        }}
                                                        onDelete={() => {
                                                            arrayHelpers.remove(fieldIndex);
                                                        }}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                    />
                                                </Item>
                                                <ButtonAddElement onElementSelected={(type) => {
                                                    arrayHelpers.insert(fieldIndex + 1, {
                                                        id: cuid(),
                                                        type,
                                                        title: `${type} Title`,
                                                        key: `${type}Key`,
                                                    });
                                                }} />
                                            </Block>
                                        );
                                    })
                                );
                            }}
                        />}
                    </Block>
                </Block>
            </Block>
            <OptionsList ref={node} show={isOpen.toString()} color="white" width={180}>
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={onDelete}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Delete</Text>
                </OptionsListItem>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={onMoveUp}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Move up</Text>
                </OptionsListItem>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <OptionsListItem row pt={16} pb={16} pl={24} pr={24} center onClick={onMoveDown}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Move down</Text>
                </OptionsListItem>
            </OptionsList>
        </Wrapper >
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Settings = styled(Block)`
    position: absolute;
    top: 18px;
    right: 18px;
`;

const OptionsList = styled(Block)`
    position: absolute;
    top: 0;
    right: -210px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const OptionsListItem = styled(Block)`
    cursor: pointer;
`;

const Item = styled(Block)`
    border: 1px solid ${colors.greyLightest};
`;

export default List;