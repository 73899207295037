import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { Block, Button, FileInput, SelectInput, Text, TextArea, TextInput } from '../../components/layout';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';

function Critics() {
    const { language } = useContext(store);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalUpdateData, setModalUpdateData] = useState(null);
    const [modalDeleteData, setModalDeleteData] = useState(null);

    const [events, setEvents] = useState([]);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryEvents, { loading: loadingEvents }] = useLazyQuery(
        gql`
            query ($search: String) {
                events(first: 3, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.events) {
                    setEvents(data.events.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryCritics, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                critics(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            description
                            thumbnail
                            thumbnailWeb
                            event {
                                id
                                name
                            }
                            type
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.critics) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.critics.edges] : data.critics.edges,
                        pageInfo: data.critics.pageInfo,
                        totalCount: data.critics.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationCriticCreate, { loading: loadingCriticCreate }] = useMutation(
        gql`
            mutation criticCreate($name: String!, $description: String!, $thumbnail: Json!, $thumbnailWeb: Json!, $event: ID, $type: CriticType!) {
                criticCreate(name: $name, description: $description, thumbnail: $thumbnail, thumbnailWeb: $thumbnailWeb, event: $event, type: $type) {
                    id
                    name
                    description
                    thumbnail
                    thumbnailWeb
                    event {
                        id
                        name
                    }
                    type
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                requestData();
                setModalCreateIsOpen(false);
            }
        }
    );

    const [mutationCriticUpdate, { loading: loadingCriticUpdate }] = useMutation(
        gql`
                mutation criticUpdate($id: ID!, $name: String!, $description: String!, $thumbnail: Json!, $thumbnailWeb: Json!, $event: ID, $type: CriticType!) {
                    criticUpdate(id: $id, name: $name, description: $description, thumbnail: $thumbnail, thumbnailWeb: $thumbnailWeb, event: $event, type: $type) {
                        id
                        name
                        description
                        thumbnail
                        thumbnailWeb
                        event {
                            id
                            name
                        }
                        type
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.criticUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.criticUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = { ...data.criticUpdate };
                        setModalUpdateData(null);
                        setResult([...result]);
                    }
                }
            }
        }
    );

    const [mutationCriticDelete, { loading: loadingCriticDelete }] = useMutation(
        gql`
            mutation criticDelete($id: ID!) {
                criticDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.criticDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.criticDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryCritics({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309, marginRight: "1px" }} text="Add Critic" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Type", key: "type" },
                            { label: "Event", key: "event" }
                        ]}
                        rows={result.edges.map((edge) => {
                            return {
                                name: edge.node.name,
                                event: !!edge.node.event ? edge.node.event.name : "-",
                                type: edge.node.type,
                                actions: <>
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateData({ ...edge.node, event: edge.node.event && edge.node.event.id })} />
                                    <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteData(edge.cursor)} />
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Critic"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Critic</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    description: yup.string().required(),
                                    thumbnail: yup.mixed().required(),
                                    thumbnailWeb: yup.mixed().required(),
                                    event: yup.number().nullable(),
                                    type: yup.string().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationCriticCreate({ variables: { ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <SelectInput name="type" label="Type" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.type} options={[{ value: "CRITIC", label: "Critic" }, { value: "EXCLUSIVE", label: "Exclusive" }]} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="description" label="Description" onChange={handleChange} onBlur={handleBlur} error={errors.description} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnail" label="App image" onChange={handleChange} onBlur={handleBlur} error={errors.thumbnail} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnailWeb" label="Web image" onChange={handleChange} onBlur={handleBlur} error={errors.thumbnailWeb} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="event" label="Event" onChange={handleChange} onBlur={handleBlur} error={errors.event} options={events} onChangeSearch={(search) => queryEvents({ variables: { search } })} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingCriticCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Critic"
                isOpen={!!modalUpdateData}
                onRequestClose={() => setModalUpdateData(null)}
            >
                <Text size={14} height={20}>Critic</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    description: yup.string().required(),
                                    thumbnail: yup.mixed().required(),
                                    thumbnailWeb: yup.mixed().required(),
                                    event: yup.number().nullable(),
                                    type: yup.string().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationCriticUpdate({ variables: { id: modalUpdateData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <SelectInput name="type" label="Type" type="text" onChange={handleChange} onBlur={handleBlur} value={values.type} error={errors.type} options={[{ value: "CRITIC", label: "Critic" }, { value: "EXCLUSIVE", label: "Exclusive" }]} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="description" label="Description" onChange={handleChange} onBlur={handleBlur} value={values.description} error={errors.description} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnail" label="App image" onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnailWeb" label="Web image" onChange={handleChange} onBlur={handleBlur} value={values.thumbnailWeb} error={errors.thumbnailWeb} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="event" label="Event" onChange={handleChange} onBlur={handleBlur} value={values.event} error={errors.event} options={events} onChangeSearch={(search) => queryEvents({ variables: { search } })} />
                                            </Block>
                                        </Block>
                                        <Block mt={44} space="between" row>
                                            <Button type="submit" text="Submit" loading={loadingCriticUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Critic"
                isOpen={!!modalDeleteData}
                onRequestClose={() => setModalDeleteData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this event?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this event.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingCriticDelete} onClick={() => mutationCriticDelete({ variables: { id: modalDeleteData } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Critics;