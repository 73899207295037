import * as React from "react"

function IconEye(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1 8.308c2.167 2.786 8 6.687 14 0-6-7.802-11.833-3.251-14 0z"
                stroke="#35383B"
                strokeLinecap="round"
            />
            <circle cx={8} cy={8} r={1} stroke="#35383B" />
        </svg>
    )
}

export default IconEye
