import * as React from "react"

function IconCities(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M3 14h7V2H3v12z" stroke="#5D6368" />
            <path d="M5 7h1V4H5v3zM7 12h1V9H7v3z" fill="#5D6368" />
            <path d="M10 14h3V4h-3v10z" stroke="#5D6368" />
        </svg>
    )
}

export default IconCities
