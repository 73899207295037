import React, { useContext } from 'react';
import { Grid, Input } from 'react-spreadsheet-grid';
import { ModalThatSlides } from '../../../../components';
import { Block, Button } from '../../../../components/layout';
import { store } from '../../../../utils/store';

function GridLinks({ rows, onChangeRows, onRequestClose, onClickCreate, onClickDelete }) {
    const { language } = useContext(store);

    const onFieldChange = (rowId, field) => (value) => {
        // Find the row that is being changed
        const row = rows.find(({ id }) => id === rowId);

        // Change a value of a field
        row[field] = value;

        onChangeRows && onChangeRows([].concat(rows));
    }

    return (
        <ModalThatSlides
            isOpen={!!rows}
            onRequestClose={onRequestClose}
            actions={(
                <Button
                    text="Add affiliates"
                    onClick={onClickCreate}
                />
            )}
        >
            {!!rows && <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
                <Block flex row>
                    <Grid
                        columns={[
                            {
                                title: () => 'Name',
                                value: (row, { focus }) => {
                                    return (
                                        <Input
                                            value={row.name}
                                            focus={focus}
                                            onChange={onFieldChange(row.id, 'name')}
                                        />
                                    );
                                }
                            },
                            {
                                title: () => 'Url',
                                value: (row, { focus }) => {
                                    return (
                                        <Input
                                            value={row.url}
                                            focus={focus}
                                            onChange={onFieldChange(row.id, 'url')}
                                        />
                                    );
                                }
                            }
                        ]}
                        rows={rows}
                        getRowKey={row => row.id}
                    />
                    <Block width={200} mt={40}>
                        {rows.map((row, index) =>
                            <Block row>
                                <button style={{ width: 100, height: 48 }} onClick={() => onClickDelete && onClickDelete(row, index)}>Delete</button>
                            </Block>
                        )}
                    </Block>
                </Block>
            </div>}
        </ModalThatSlides>
    );
}

export default GridLinks;