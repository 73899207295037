import React from 'react';
import { Block, Text } from '../../../../components/layout';

function SubRow({ title, date, time, actions }) {
    return (
        <Block>
            <Block width="100%" height={1} color="greyAlmostWhite" />
            <Block row height={52} pl={40} color="greyWhite" center space="between">
                <Block>
                    <Text size={14} height={20}>{title}</Text>
                </Block>
                <Block>
                    <Text size={14} height={20}>{date}</Text>
                </Block>
                <Block>
                    <Text size={14} height={20}>{time}</Text>
                </Block>
                <Block row>
                    {actions}
                </Block>
            </Block>
        </Block>
    );
}

export default SubRow;