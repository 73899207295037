import PropTypes from 'prop-types'
import React from 'react'

class SpreadsheetGridInputCustomType extends React.PureComponent {
    constructor(props) {
        super(props)

        this.onKeyDown = this.onKeyDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)

        this.state = {
            props,
            value: this.props.value,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState.props) {
            return {
                ...prevState,
                props: nextProps,
                value: nextProps.value,
            }
        }
        return prevState
    }

    componentDidMount() {
        this.prepareFocus(this.props.focus)
    }

    componentDidUpdate(prevProps) {
        // Don't touch focus if the state is updating
        if (this.props !== prevProps) {
            this.prepareFocus(this.props.focus)
        }
    }

    onKeyDown(e) {
        if (e.keyCode === 13 || e.keyCode === 9) {
            e.preventDefault()
            this.input.blur()
        }
    }

    onChange(e) {
        const value = e.target.value

        this.setState({
            value,
        })
    }

    onBlur() {
        if (this.props.onChange) {
            this.props.onChange(this.input.value)
        }
    }

    prepareFocus(focus) {
        if (focus) {
            this.input.focus()
            if (this.props.selectTextOnFocus) {
                this.input.select()
            }
        } else if (this.input === document.activeElement) {
            this.input.blur()
        }
    }

    render() {
        return (
            <input
                type={this.props.type}
                className='SpreadsheetGridInputCustomType'
                value={this.state.value}
                placeholder={this.props.placeholder}
                ref={(input) => (this.input = input)}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        )
    }
}

SpreadsheetGridInputCustomType.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    selectTextOnFocus: PropTypes.bool,
}

SpreadsheetGridInputCustomType.defaultProps = {
    value: '',
    selectTextOnFocus: false,
}

export default SpreadsheetGridInputCustomType
