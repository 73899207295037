import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Input } from 'react-spreadsheet-grid';
import { Block, Button } from '../../../../components/layout';
import colors from '../../../../utils/colors';
import { store } from '../../../../utils/store';
import FileInput from '../../components/FileInput';
import SelectMultiple from '../../components/Select';

function GridEvents({ rows, onChangeRows, onClickSessions, onClickCreate, onClickDelete }) {
    const { language } = useContext(store);
    const [areas, setAreas] = useState([]);

    const [queryAreas, { loading: loadingAreas }] = useLazyQuery(
        gql`
            query ($search: String) {
                areas(first: 10000, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setAreas(data.areas.edges.map((edge) => edge.node));
                }
            }
        }
    );

    const onFieldChange = (rowId, field) => (value) => {
        // Find the row that is being changed
        const row = rows.find(({ id }) => id === rowId);

        // Change a value of a field
        row[field] = value;

        onChangeRows && onChangeRows([].concat(rows));
    }

    useEffect(() => {
        queryAreas();
    }, [language]);

    return (
        <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
            <Block flex row width={"100%"} height={"100%"}>
                <Grid
                    columns={[
                        {
                            title: () => 'Name',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.name}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'name')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'App Image',
                            value: (row, { focus }) => {
                                return (
                                    <FileInput
                                        value={row.thumbnail}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'thumbnail')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Web image',
                            value: (row, { focus }) => {
                                return (
                                    <FileInput
                                        value={row.thumbnailWeb}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'thumbnailWeb')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Title',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.aboutTitle}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'aboutTitle')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Description',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.aboutDescription}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'aboutDescription')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Areas',
                            value: (row, { focus }) => {
                                return (
                                    <SelectMultiple
                                        selectedId={row.areas}
                                        isOpen={focus}
                                        items={areas}
                                        onChange={onFieldChange(row.id, 'areas')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Duration',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.duration}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'duration')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Price Min',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.minTickerPrice}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'minTickerPrice')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Price Max',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.maxTickerPrice}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'maxTickerPrice')}
                                    />
                                );
                            }
                        },
                        {
                            title: () => 'Age',
                            value: (row, { focus }) => {
                                return (
                                    <Input
                                        value={row.minAge}
                                        focus={focus}
                                        onChange={onFieldChange(row.id, 'minAge')}
                                    />
                                );
                            }
                        }
                    ]}
                    rows={rows}
                    getRowKey={row => row.id}
                />
                <Block width={200} mt={40}>
                    {rows.map((row, index) =>
                        <Block row>
                            <button style={{ width: 100, height: 48 }} onClick={() => { onClickSessions && onClickSessions(row, index); }}>Sessions</button>
                            <button style={{ width: 100, height: 48 }} onClick={() => { onClickDelete && onClickDelete(row, index); }}>Delete</button>
                        </Block>
                    )}
                </Block>
            </Block>
        </div>
    );
}

export default GridEvents;