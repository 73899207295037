import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, CheckBox, FileInput, SelectInput, Text, TextArea, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCheck, SvgIconClose, SvgIconDown, SvgIconEdit, SvgIconModalDelete, SvgIconTrash, SvgIconUp } from '../../svgs';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';
import SubRow from './components/SubRow';
import TicketsInput from './components/TicketsInput';

function EventsImported() {
    const { language } = useContext(store);
    const inputRef = useRef(null);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");

    const [modalUpdateData, setModalUpdateData] = useState(null);
    const [modalDeleteData, setModalDeleteData] = useState(null);

    const [modalRejectIsOpen, setModalRejectIsOpen] = useState(null);
    const [modalApproveIsOpen, setModalApproveIsOpen] = useState(null);
    const [rejectionObservations, setRejectionObservations] = useState("");

    const [modalUpdatePlaceData, setModalUpdatePlaceData] = useState(null);
    const [modalDeletePlaceData, setModalDeletePlaceData] = useState(null);
    const [rowsOpened, setRowsOpened] = useState([]);


    const [areas, setAreas] = useState([]);
    const [places, setPlaces] = useState([]);
    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryOthers, { loading: loadingOthers }] = useLazyQuery(
        gql`
            query {
                areas(first: 1000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
                places(first: 1000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setAreas(data.areas.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
                if (data && data.places) {
                    setPlaces(data.places.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryEvents, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                eventsImported(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            thumbnail
                            duration
                            minTickerPrice
                            maxTickerPrice
                            minAge
                            aboutTitle
                            aboutDescription
                            status
                            areas {
                                id
                                name
                            }
                            places {
                                id
                                event {
                                    id
                                }
                                place {
                                    id
                                    name
                                }
                                isVirtual
                                isInPerson
                                ticketsUrls
                                date
                                time
                            }
                            featured
                            recommended
                            imported
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventsImported) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.eventsImported.edges] : data.eventsImported.edges,
                        pageInfo: data.eventsImported.pageInfo,
                        totalCount: data.eventsImported.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationEventUpdate, { loading: loadingEventUpdate }] = useMutation(
        gql`
                mutation eventUpdate($id: ID!, $name: String, $thumbnail: Json, $duration: String, $minTickerPrice: String, $maxTickerPrice: String, $minAge: String, $aboutTitle: String, $aboutDescription: String, $status: EventStatus, $areas: [ID!], $featured: Boolean, $recommended: Boolean) {
                    eventUpdate(id: $id, name: $name, thumbnail: $thumbnail, duration: $duration, minTickerPrice: $minTickerPrice, maxTickerPrice: $maxTickerPrice, minAge: $minAge, aboutTitle: $aboutTitle, aboutDescription: $aboutDescription, status: $status, areas: $areas, featured: $featured, recommended: $recommended) {
                        id
                        name
                        thumbnail
                        duration
                        minTickerPrice
                        maxTickerPrice
                        minAge
                        aboutTitle
                        aboutDescription
                        status
                        areas {
                            id
                            name
                        }
                        places {
                            id
                            event {
                                id
                            }
                            place {
                                id
                                name
                            }
                            isVirtual
                            isInPerson
                            ticketsUrls
                            date
                            time
                        }
                        featured
                        recommended
                        imported
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = { ...data.eventUpdate };
                        setModalUpdateData(null);
                        setResult([...result]);
                    }
                }
            }
        }
    );

    const [mutationEventDelete, { loading: loadingEventDelete }] = useMutation(
        gql`
            mutation eventDelete($id: ID!) {
                eventDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationEventUpdatePlace, { loading: loadingEventUpdatePlace }] = useMutation(
        gql`
            mutation eventUpdatePlace($id: ID!, $placeId: ID, $isVirtual: Boolean, $isInPerson: Boolean, $ticketsUrls: Json, $date: String!, $time: String) {
                eventUpdatePlace(id: $id, placeId: $placeId, isVirtual: $isVirtual, isInPerson: $isInPerson, ticketsUrls: $ticketsUrls, date: $date, time: $time) {
                    id
                    event {
                        id
                    }
                    place {
                        id
                        name
                    }
                    isVirtual
                    isInPerson
                    ticketsUrls
                    date
                    time
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventUpdatePlace) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventUpdatePlace.event.id);
                    if (index !== -1) {
                        const indexPlaces = result.edges[index].node.places.findIndex((place) => place.id == data.eventUpdatePlace.id);
                        if (indexPlaces !== -1) {
                            result.edges[index].node.places[indexPlaces] = data.eventUpdatePlace;
                            setModalUpdatePlaceData(null);
                            setResult([...result]);
                        }
                    }
                }
            }
        }
    );

    const [mutationEventDeletePlace, { loading: loadingEventDeletePlace }] = useMutation(
        gql`
            mutation eventDeletePlace($id: ID!) {
                eventDeletePlace(id: $id) {
                    id
                    event {
                        id
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventDeletePlace) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventDeletePlace.event.id);
                    if (index !== -1) {
                        const indexPlaces = result.edges[index].node.places.findIndex((place) => place.id == data.eventDeletePlace.id);
                        if (indexPlaces !== -1) {
                            result.edges[index].node.places.splice(indexPlaces, 1);
                            setModalDeletePlaceData(null);
                            setResult([...result]);
                        }
                    }
                }
            }
        }
    );

    const [mutationEventImport, { loading: loadingEventImport }] = useMutation(
        gql`
            mutation eventImport($id: ID!, $approved: Boolean!) {
                eventImport(id: $id, approved: $approved) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.eventImport) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.eventImport.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalRejectIsOpen(null);
                        setModalApproveIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryEvents({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        queryOthers();
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>

                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Imported from", key: "imported" }
                        ]}
                        rows={result.edges.map((edge) => {
                            const rowOpenedIndex = rowsOpened.findIndex((rowOpened) => rowOpened == edge.cursor);

                            return {
                                name: edge.node.name,
                                imported: edge.node.imported,
                                subRowsOpen: !!rowsOpened.find((rowOpened) => rowOpened == edge.cursor),
                                subRows: edge.node.places && edge.node.places.map((place) => {
                                    return (
                                        <SubRow
                                            key={place.id}
                                            title={place.place.name}
                                            date={place.date}
                                            time={place.time}
                                            actions={<>
                                                <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdatePlaceData({ ...place, placeId: place.place.id })} />
                                                <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeletePlaceData(place.id)} />
                                            </>}
                                        />
                                    );
                                }),
                                actions: <>
                                    <ButtonAction icon={<SvgIconCheck color={colors.greyDark} />} onClick={() => setModalApproveIsOpen(edge.cursor)} />
                                    <ButtonAction icon={<SvgIconClose color={colors.greyDark} />} onClick={() => setModalRejectIsOpen(edge.cursor)} />
                                    {(edge.node.places && edge.node.places.length > 0) && <ButtonAction icon={rowOpenedIndex === -1 ? <SvgIconUp /> : <SvgIconDown />} text="Places" onClick={() => {
                                        if (rowOpenedIndex === -1) {
                                            rowsOpened.push(edge.cursor);
                                        } else {
                                            rowsOpened.splice(rowOpenedIndex, 1);
                                        }
                                        setRowsOpened([...rowsOpened]);
                                    }} />}
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateData({ ...edge.node, areas: edge.node.areas.map((area) => area.id) })} />
                                    {/* <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteData(edge.cursor)} /> */}
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Update Event"
                isOpen={!!modalUpdateData}
                onRequestClose={() => setModalUpdateData(null)}
            >
                <Text size={14} height={20}>Event</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string(),
                                    thumbnail: yup.mixed(),
                                    duration: yup.string().nullable(),
                                    minTickerPrice: yup.number().nullable(),
                                    maxTickerPrice: yup.number().nullable(),
                                    minAge: yup.string().nullable(),
                                    aboutTitle: yup.string().nullable(),
                                    aboutDescription: yup.string().nullable(),
                                    // status: yup.string().required(),
                                    areas: yup.array(yup.number())
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventUpdate({ variables: { id: modalUpdateData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <FileInput name="thumbnail" label="Thumbnail" onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="duration" label="Duration" type="text" onChange={handleChange} onBlur={handleBlur} value={values.duration} error={errors.duration} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minTickerPrice" label="Min ticket price" type="text" onChange={handleChange} onBlur={handleBlur} value={values.minTickerPrice} error={errors.minTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="maxTickerPrice" label="Max ticket price" type="text" onChange={handleChange} onBlur={handleBlur} value={values.maxTickerPrice} error={errors.maxTickerPrice} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="minAge" label="Min age" type="text" onChange={handleChange} onBlur={handleBlur} value={values.minAge} error={errors.minAge} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="aboutTitle" label="About title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.aboutTitle} error={errors.aboutTitle} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextArea name="aboutDescription" label="About description" onChange={handleChange} onBlur={handleBlur} value={values.aboutDescription} error={errors.aboutDescription} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput name="areas" label="Areas" onChange={handleChange} onBlur={handleBlur} isMulti error={errors.areas} options={areas} />
                                            </Block>
                                            {/* <Block mt={12}>
                                                <SelectInput name="status" label="Status" type="text" onChange={handleChange} onBlur={handleBlur} value={values.status} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                            </Block> */}
                                            <Block mt={12}>
                                                <CheckBox name="featured" label="Featured" onChange={handleChange} onBlur={handleBlur} value={values.featured} error={errors.featured} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="recommended" label="Recommended" onChange={handleChange} onBlur={handleBlur} value={values.recommended} error={errors.recommended} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingEventUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Delete Event"
                isOpen={!!modalDeleteData}
                onRequestClose={() => setModalDeleteData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this event?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this event.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingEventDelete} onClick={() => mutationEventDelete({ variables: { id: modalDeleteData } })} />
                </Block>
            </Modal>
            <Modal
                title="Update Place"
                isOpen={!!modalUpdatePlaceData}
                onRequestClose={() => setModalUpdatePlaceData(null)}
            >
                <Text size={14} height={20}>Place</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdatePlaceData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    placeId: yup.number().required(),
                                    isVirtual: yup.boolean().nullable(),
                                    isInPerson: yup.boolean().nullable(),
                                    ticketsUrls: yup.array(yup.object().shape({ name: yup.string().required(), url: yup.string().url().required() })),
                                    date: yup.date().required(),
                                    time: yup.string().nullable()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationEventUpdatePlace({ variables: { id: modalUpdatePlaceData.id, ...values, time: values.time === "" ? null : values.time } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <SelectInput name="placeId" label="Place" onChange={handleChange} onBlur={handleBlur} error={errors.placeId} options={places} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isVirtual" label="Is virtual" onChange={handleChange} onBlur={handleBlur} value={values.isVirtual} error={errors.isVirtual} />
                                            </Block>
                                            <Block mt={12}>
                                                <CheckBox name="isInPerson" label="Is in person" onChange={handleChange} onBlur={handleBlur} value={values.isInPerson} error={errors.isInPerson} />
                                            </Block>
                                            <Block mt={12}>
                                                <TicketsInput name="ticketsUrls" label="Tickets URL" onChange={handleChange} onBlur={handleBlur} value={values.ticketsUrls} errors={errors} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="date" label="Date" type="date" onChange={handleChange} onBlur={handleBlur} value={values.date} error={errors.date} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="time" label="Time" type="time" onChange={handleChange} onBlur={handleBlur} value={values.time} error={errors.time} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingEventUpdatePlace} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Delete Place"
                isOpen={!!modalDeletePlaceData}
                onRequestClose={() => setModalDeletePlaceData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this place?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this place.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingEventDeletePlace} onClick={() => mutationEventDeletePlace({ variables: { id: modalDeletePlaceData } })} />
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Approve event"
                isOpen={!!modalApproveIsOpen}
                onRequestClose={() => setModalApproveIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to approve this event?</Text>
                <Block mt={32}>
                    <Button text="Approve" loading={loadingEventImport} onClick={() => mutationEventImport({ variables: { id: modalApproveIsOpen, approved: true } })} />
                </Block>
            </Modal>
            <Modal
                width={300}
                title="Reject event"
                isOpen={!!modalRejectIsOpen}
                onRequestClose={() => { setModalRejectIsOpen(null); setRejectionObservations(""); }}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to reject this event?</Text>
                <Block mt={32}>
                    <Button text="Reject" loading={loadingEventImport} onClick={() => mutationEventImport({ variables: { id: modalRejectIsOpen, approved: false } })} />
                </Block>
            </Modal>
        </>
    );
}

export default EventsImported;