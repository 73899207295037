import { gql, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Block, Button, Text, TextInput } from '../../components/layout';
import { SvgLogo } from '../../svgs';
import { getClientToken, setClientToken } from '../../utils/client';
import validateFormSchema from '../../utils/validateFormSchema';

function Login() {
    const [cookies, setCookie] = useCookies(["backoffice_session"]);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [requested, setRequested] = useState(false);

    const [userRequestCode, { loading: loadingCode }] = useMutation(
        gql`
            mutation userRequestCode($email: String!) {
                userRequestCode(email: $email)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data.userRequestCode) {
                    setRequested(true);
                }
            }
        }
    );

    const [userLogin, { loading: loadingLogin }] = useMutation(
        gql`
            mutation userLogin($email: String!, $code: String!) {
                userLogin(email: $email, code: $code) {
                    token
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data.userLogin) {
                    setClientToken(data.userLogin.token);
                    setCookie("backoffice_session", data.userLogin.token, { path: '/' });
                }
            }
        }
    );

    if (getClientToken() || cookies.backoffice_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Block color="greyDarker" width="100%" height="100%" center middle>
            <Block color="white" width={420} height={416} pl={44} pr={44} middle>
                <Block>
                    <Text size={24} height={28}>Hello, welcome back</Text>
                    <Text size={12} height={15} mt={4} color="grey">Insert your credentials to login into your account</Text>
                </Block>
                {!requested && <Block mt={32}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    email: yup.string().email().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            setEmail(values.email)
                            userRequestCode({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="email" label="E-mail" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.email} />
                                        </Block>
                                        <Block mt={24}>
                                            <Button type="submit" text="Request code" loading={loadingCode} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>}
                {requested && <Block mt={32}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    code: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            userLogin({ variables: { email, code: values.code } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="code" label="Code" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.code} />
                                        </Block>
                                        <Block mt={24}>
                                            <Button type="submit" text="Login" loading={loadingLogin} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>}
            </Block>
        </Block >
    );
}

export default Login;
