import React from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled from 'styled-components';
import { SvgIconClose } from '../../svgs';
import Header from '../Header';
import { Block, Button, Text } from '../layout';

export default function ModalThatSlides({ title, onRequestClose, actions, children, ...props }) {
    return (
        <SlidingPane
            className="sliding-modal"
            overlayClassName="sliding-modal-overlay"
            hideHeader={true}
            onRequestClose={onRequestClose}
            {...props}
        >
            <Block height="100%">
                <Header
                    leftChildren={
                        <Block height="100%">
                            <Block height="100%" middle>
                                <Text size={18} height={32} ml={24}>{title}</Text>
                            </Block>
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            {actions}
                            <Block ml={1}>
                                <Button style={{ width: 52, minWidth: 52, textAlign: "center" }} onClick={onRequestClose}>
                                    <SvgIconClose />
                                </Button>
                            </Block>
                        </Block>
                    }
                />
                <Content color="greyAlmostWhite">
                    <Block pl={120} pr={120} pt={56} pb={56} height={"100%"}>
                        {children}
                    </Block>
                </Content>
            </Block>
        </SlidingPane>
    );
}

const Content = styled(Block)`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    overflow: auto;
`;