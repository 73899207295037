import React from 'react'
import colors from '../../../../utils/colors'
import Dropdown from '../Dropdown'
import './styles.css'

class SpreadsheetGridSelect extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onGlobalKeyDown = this.onGlobalKeyDown.bind(this)
    this.onItemMouseLeave = this.onItemMouseLeave.bind(this)

    this.state = {
      isOpen: this.props.isOpen,
      selectedId: this.props.selectedId,
    }
  }

  static getDerivedStateFromProps({ isOpen, selectedId }, prevState) {
    return {
      ...prevState,
      isOpen,
      selectedId,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onGlobalKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onGlobalKeyDown, false)
  }

  onItemClick(selectedId, item) {
    const aux = this.state.selectedId || [];
    if (aux.find((id) => id === selectedId)) {
      selectedId = aux.filter((id) => id !== selectedId);
    } else {
      selectedId = [...aux, selectedId];
    }

    this.setState(
      {
        selectedId,
        isOpen: false,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(selectedId, item)
        }
      }
    )
  }

  onItemMouseEnter(selectedIndex) {
    this.setState({
      selectedIndex,
    })
  }

  onItemMouseLeave() {
    this.setState({
      selectedIndex: null,
    })
  }

  onGlobalKeyDown(e) {
    if (this.state.isOpen) {
      if (e.keyCode === 40) {
        e.preventDefault()
        if (this.state.selectedIndex || this.state.selectedIndex === 0) {
          this.setState({
            selectedIndex: this.state.selectedIndex + 1,
          })
        } else {
          this.setState({
            selectedIndex: 0,
          })
        }

        if (this.state.selectedIndex > this.props.items.length - 1) {
          this.setState({
            selectedIndex: 0,
          })
        }
      }
      if (e.keyCode === 38) {
        e.preventDefault()
        if (this.state.selectedIndex || this.state.selectedIndex === 0) {
          this.setState({
            selectedIndex: this.state.selectedIndex - 1,
          })
        } else {
          this.setState({
            selectedIndex: this.props.items.length - 1,
          })
        }

        if (this.state.selectedIndex < 0) {
          this.setState({
            selectedIndex: this.props.items.length - 1,
          })
        }
      }
      if (e.keyCode === 13 || e.keyCode === 9) {
        if (this.state.selectedIndex || this.state.selectedIndex === 0) {
          const selectedItem = this.props.items[this.state.selectedIndex]
          this.onItemClick(selectedItem.id, selectedItem)
        }
      }

      e.preventDefault()
    }
  }

  getItemClassName(isSelected) {
    return (
      'SpreadsheetGridSelectItem' +
      (isSelected ? ' SpreadsheetGridSelectItem_selected' : '')
    )
  }

  isHasValue() {
    return this.state.selectedId && this.props.items
  }

  getHeaderValue() {
    let value

    if (this.isHasValue()) {
      value = this.props.items.filter((item) => this.props.selectedId.find((id) => id === item.id));
      value = value.length > 0 ? value.map((item) => item.name).join(", ") : value;
    } else {
      value = this.props.placeholder
    }

    return value
  }

  getHeaderClassName() {
    return (
      'SpreadsheetGridSelectHeader' +
      (this.state.isOpen ? ' SpreadsheetGridSelectHeader_open' : '')
    )
  }

  renderHeader() {
    return (
      <div className={this.getHeaderClassName()}>
        <div className='SpreadsheetGridSelectValue'>
          {this.getHeaderValue()}
        </div>
      </div>
    )
  }

  renderBody() {
    const items = this.props.items
    const selectedId = this.state.selectedId || [];

    return (
      <div>
        {items &&
          items.map((item, i) => {
            return (
              <div
                key={i}
                className={this.getItemClassName(
                  i === this.state.selectedIndex
                )}
                onClick={this.onItemClick.bind(this, item.id, item)}
                onMouseEnter={this.onItemMouseEnter.bind(this, i)}
                onMouseLeave={this.onItemMouseLeave}
                style={{ background: selectedId.find((id) => item.id === id) ? colors.greyAlmostWhite : "" }}
              >
                {item.name}
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    return (
      <Dropdown
        header={this.renderHeader()}
        body={this.renderBody()}
        isOpen={this.state.isOpen}
      />
    )
  }
}

export default SpreadsheetGridSelect
