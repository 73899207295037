import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Select } from 'react-spreadsheet-grid';
import { ModalThatSlides } from '../../../../components';
import { Block, Button } from '../../../../components/layout';
import { store } from '../../../../utils/store';
import SpreadsheetGridInputCustomType from '../InputCustomType';

function GridSessions({ rows, onChangeRows, onRequestClose, onClickAffiliates, onClickCreate, onClickDelete }) {
    const { language } = useContext(store);
    const [places, setPlaces] = useState([]);

    const [queryPlaces, { loading: loadingPlaces }] = useLazyQuery(
        gql`
            query ($search: String) {
                places(first: 10000, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.places) {
                    setPlaces(data.places.edges.map((edge) => edge.node));
                }
            }
        }
    );

    const onFieldChange = (rowId, field) => (value) => {
        // Find the row that is being changed
        const row = rows.find(({ id }) => id === rowId);

        // Change a value of a field
        row[field] = value;

        onChangeRows && onChangeRows([].concat(rows));
    }

    useEffect(() => {
        queryPlaces();
    }, [language]);


    return (
        <ModalThatSlides
            isOpen={!!rows}
            onRequestClose={onRequestClose}
            actions={(
                <Button
                    text="Add session"
                    onClick={onClickCreate}
                />
            )}
        >
            {!!rows && <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
                <Block row flex>
                    <Grid
                        columns={[
                            {
                                title: () => 'Place',
                                value: (row, { focus }) => {
                                    const defaultItems = row.place ? [places.find((place) => row.place === place.id)] : [];

                                    return (
                                        <Select
                                            selectedId={row.place}
                                            isOpen={focus}
                                            items={focus ? places : defaultItems}
                                            onChange={onFieldChange(row.id, 'place')}
                                        />
                                    );
                                }
                            },
                            {
                                title: () => 'Is Virtual',
                                value: (row, { focus }) => {
                                    console.log(focus)
                                    if (focus) {
                                        onFieldChange(row.id, 'isVirtual')(!row.isVirtual);
                                    }

                                    return (
                                        <input
                                            type="checkbox"
                                            checked={row.isVirtual}
                                            onChange={(e) => onFieldChange(row.id, 'isVirtual')(e.target.checked)}
                                        />
                                    );
                                }
                            },
                            {
                                title: () => 'Is In Person',
                                value: (row, { focus }) => {
                                    if (focus) {
                                        onFieldChange(row.id, 'isInPerson')(!row.isInPerson);
                                    }

                                    return (
                                        <input
                                            type="checkbox"
                                            checked={row.isInPerson}
                                            onChange={(e) => onFieldChange(row.id, 'isInPerson')(e.target.checked)}
                                        />
                                    );
                                }
                            },
                            {
                                title: () => 'Date',
                                value: (row, { focus }) => {
                                    return (
                                        <SpreadsheetGridInputCustomType
                                            type="date"
                                            value={row.date}
                                            focus={focus}
                                            onChange={onFieldChange(row.id, 'date')}
                                        />
                                    );
                                }
                            },
                            {
                                title: () => 'Time',
                                value: (row, { focus }) => {
                                    return (
                                        <SpreadsheetGridInputCustomType
                                            type="time"
                                            value={row.time}
                                            focus={focus}
                                            onChange={onFieldChange(row.id, 'time')}
                                        />
                                    );
                                }
                            }
                        ]}
                        rows={rows}
                        getRowKey={row => row.id}
                    />
                    <Block width={200} mt={40}>
                        {rows.map((row, index) =>
                            <Block row>
                                <button style={{ width: 100, height: 48 }} onClick={() => { onClickAffiliates && onClickAffiliates(row, index); }}>Affiliates</button>
                                <button style={{ width: 100, height: 48 }} onClick={() => onClickDelete && onClickDelete(row, index)}>Delete</button>
                            </Block>
                        )}
                    </Block>
                </Block>
            </div>}
        </ModalThatSlides>
    );
}

export default GridSessions;