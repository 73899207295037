import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';

function Areas() {
    const { language } = useContext(store);
    const inputRef = useRef(null);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalUpdateData, setModalUpdateData] = useState(null);
    const [modalDeleteData, setModalDeleteData] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryAreas, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                areas(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.areas.edges] : data.areas.edges,
                        pageInfo: data.areas.pageInfo,
                        totalCount: data.areas.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );


    const [mutationAreaCreate, { loading: loadingAreaCreate }] = useMutation(
        gql`
            mutation areaCreate($name: String!) {
                areaCreate(name: $name) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                requestData();
                setModalCreateIsOpen(false);
            }
        }
    );

    const [mutationAreaUpdate, { loading: loadingAreaUpdate }] = useMutation(
        gql`
                mutation areaUpdate($id: ID!, $name: String) {
                    areaUpdate(id: $id, name: $name) {
                        id
                        name
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.areaUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = { ...data.areaUpdate };
                        setModalUpdateData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationAreaDelete, { loading: loadingAreaDelete }] = useMutation(
        gql`
            mutation areaDelete($id: ID!) {
                areaDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.areaDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationAreaImportCsv, { loading: loadingAreaImportCsv }] = useMutation(
        gql`
            mutation areaImportCsv($file: Upload!) {
                areaImportCsv(file: $file)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaImportCsv) {
                    inputRef.current.value = null;
                    requestData();
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryAreas({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <input ref={inputRef} type="file" style={{ display: "none" }} onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                    mutationAreaImportCsv({ variables: { file: e.target.files[0] } });
                }
            }} />
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309 }} text="Load CSV" onClick={() => inputRef.current.click()} style={{ marginRight: "1px" }} loading={loadingAreaImportCsv} />
                            <Button style={{ minWidth: 309 }} text="Add Area" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" }
                        ]}
                        rows={result.edges.map((edge) => {
                            return {
                                name: edge.node.name,
                                actions: <>
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateData(edge.node)} />
                                    <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteData(edge.cursor)} />
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Area"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Area</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationAreaCreate({ variables: { ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingAreaCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Area"
                isOpen={!!modalUpdateData}
                onRequestClose={() => setModalUpdateData(null)}
            >
                <Text size={14} height={20}>Area</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationAreaUpdate({ variables: { id: modalUpdateData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingAreaUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Area"
                isOpen={!!modalDeleteData}
                onRequestClose={() => setModalDeleteData(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this area?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this area.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingAreaDelete} onClick={() => mutationAreaDelete({ variables: { id: modalDeleteData } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Areas;