import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Block, Text } from '../../../../layout';
import { SvgIconPlus, SvgIconTextLine } from '../../../../../svgs';
import colors from '../../../../../utils/colors';

function ButtonAddElement({ showSection = false, onElementSelected }) {
    const [isOpen, setIsOpen] = useState(false);

    const node = useRef();
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const itemClick = (type) => {
        onElementSelected(type);
        setIsOpen(false);
    }

    return (
        <Wrapper ref={node} width="100%" mt={12}>
            <Bar selected={isOpen} width="100%" height={28} middle center onClick={() => setIsOpen(true)}>
                <Plus />
            </Bar>
            <List show={isOpen.toString()} color="white" width={180}>
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("TextLine")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Text line</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("TextMultiLine")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Text multi line</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("TextEditor")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Text editor</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("File")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>File</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("Button")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>Button</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("List")}>
                    <SvgIconTextLine />
                    <Text size={14} height={20} ml={10}>List</Text>
                </Item>
                {showSection && <>
                    <Block width="100%" height={1} color="greyAlmostWhite" />
                    <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => itemClick("Section")}>
                        <SvgIconTextLine />
                        <Text size={14} height={20} ml={10}>Section</Text>
                    </Item>
                </>}
            </List>
        </Wrapper>

    );
}

const Plus = styled(SvgIconPlus)``;

const Wrapper = styled(Block)`
    position: relative;
`;

const Bar = styled(Block)`
    position: relative;
    cursor: pointer;
    background-color: ${({ selected }) => selected ? colors.grey : colors.greyLightest};

    ${Plus} * {
        stroke: ${({ selected }) => selected ? "white" : colors.greyLight};
    }

    :hover {
        background-color: ${colors.grey};

        ${Plus} * {
            stroke: white;
        }
    }
`;

const List = styled(Block)`
    z-index: 1;
    position: absolute;
    top: 0;
    right: -210px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Item = styled(Block)`
    cursor: pointer;
`;

export default ButtonAddElement;