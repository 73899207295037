import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, Text } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEye, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import colors from '../../utils/colors';

function PlacesSuggested() {
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);
    const [modalDetailIsOpen, setModalDetailIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [querySuggestedPlaces, { loading }] = useLazyQuery(
        gql`
            query suggestedPlaces($first: Int, $after: String, $search: String) {
                suggestedPlaces(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            text
                            promoter {
                                id
                                company
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.suggestedPlaces) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.suggestedPlaces.edges] : data.suggestedPlaces.edges,
                        pageInfo: data.suggestedPlaces.pageInfo,
                        totalCount: data.suggestedPlaces.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationSuggestedPlaceDelete, { loading: loadingSuggestedPlaceDelete }] = useMutation(
        gql`
            mutation suggestedPlaceDelete($id: ID!) {
                suggestedPlaceDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.suggestedPlaceDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.suggestedPlaceDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setModalDetailIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        querySuggestedPlaces({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<></>}
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Text", key: "text" },
                            { label: "Promoter", key: "promoter" },
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, text: edge.node.text.length > 100 ? edge.node.text.slice(0, 97) + "..." : edge.node.text, promoter: edge.node.promoter.company, actions: <><ButtonAction icon={<SvgIconEye color={colors.greyDark} />} onClick={() => setModalDetailIsOpen(edge.node)} /><ButtonAction icon={<SvgIconTrash color={colors.greyDark} />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                width={300}
                title="Place Suggestion"
                isOpen={!!modalDetailIsOpen}
                onRequestClose={() => setModalDetailIsOpen(null)}
            >
                <Text size={14} height={20}>{modalDetailIsOpen && modalDetailIsOpen.text}</Text>
            </Modal>
            <Modal
                width={300}
                title="Delete place suggestion"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this place suggestion?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this place suggestion.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingSuggestedPlaceDelete} onClick={() => mutationSuggestedPlaceDelete({ variables: { id: modalDeleteIsOpen, approved: false } })} />
                </Block>
            </Modal>
        </>
    );
}

export default PlacesSuggested;